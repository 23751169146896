<script>
    import { mapActions } from "vuex";
    import { apiUrl } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";

    export default {
        name: "ProductCard",
        props: {
            product: Object,
            position: Number,
            isSearch: Boolean,
            isAvalible: Boolean,
            isSmall: Boolean,
        },
        data() {
            // временный костыль на отсутсвие оферов
            let hide = true;

            let offer = this.isAvalible
                ? this.product.offers.filter(
                      (offer) => offer.available && offer.quantity > 0
                  )[0]
                : this.product.offers[0];

            // let offer = this.goods.offers.filter(
            //     offer => offer.available && offer.quantity > 0
            // )[0];
            if (offer === undefined) {
                hide = false;
            }

            return {
                isHide: hide,
                currentOfferId: (offer || {}).id || "",
                scrImage: ((this.product || {}).previewImage || {}).medium || "",
                price: (offer || {}).price || "",
                discountPrice: (offer || {}).discountPrice || "",
                discountPercent: (offer || {}).discountPercent || "",
                inCart: this.product.inCart || "",
                loading: false,
                scrImagePreview:
                    ((this.product || {}).additionalPreviewImage || {}).medium || "",
            };
        },
        computed: {
            isSale() {
                return this.discountPercent;
            },
            textForBtn() {
                return this.inCart ? "В корзине" : "В корзину";
            },
        },
        beforeDestroy() {
            this.$eventBus.$off("add-cart");
        },
        methods: {
            apiUrl,
            ...mapActions("Root", ["addProductInCart"]),

            async addCart() {
                if (this.inCart) {
                    this.$router.push({ name: "Order" });
                    return;
                }
                this.loading = true;
                this.addProductInCart({
                    quantity: 1,
                    productId: this.currentOfferId,
                }).then(() => {
                    this.inCart = true;
                    this.$eventBus.$emit("add-cart");
                    this.loading = false;
                    this.sendDataLayerEvent({ type: "cart" });
                });
            },

            showPreOrderPopup() {
                this.$popup.show("modal-pre-order", { productId: this.product.id });
            },

            sendDataLayerEvent({ type }) {
                if (type === "click") {
                    sendAnalytics.send(sendAnalytics.events.productClick, {
                        name: this.product.name,
                        offerId: this.currentOfferId,
                        price: this.discountPrice,
                        position: this.position,
                    });
                }
                if (type === "cart") {
                    sendAnalytics.send(sendAnalytics.events.addToCart, {
                        name: this.product.name,
                        offerId: this.currentOfferId,
                        price: this.discountPrice,
                    });
                }
            },
        },
    };
</script>

<template>
    <div
        v-if="isHide"
        class="goods-card"
        :class="{
            'goods-card--in-cart visible': inCart,
            'goods-card--loading visible': loading,
        }"
    >
        <div class="goods-card__img">
            <router-link
                @click.native.prevent="sendDataLayerEvent({ type: 'click' })"
                :to="{
                    name: 'Product',
                    params: { slug: product.slug },
                }"
                class="goods-card__img-link js-lazy-load"
                tabindex="-1"
            >
                <img
                    :data-src="apiUrl(scrImage)"
                    :src="require('@/assets/images/preloader_card.gif')"
                    alt=""
                />
            </router-link>

            <div v-if="isSale" class="goods-card__labels">
                <div class="goods-card__label goods-card__label--sale">
                    -{{ discountPercent }}%
                </div>
            </div>
        </div>

        <router-link
            @click.native.prevent="sendDataLayerEvent({ type: 'click' })"
            :to="{
                name: 'Product',
                params: { slug: product.slug },
            }"
            class="goods-card__hover"
            tabindex="-1"
        ></router-link>

        <div class="goods-card__price goods-card__z-top">
            <div
                class="goods-card__price-num goods-card__price-num--current"
                :class="{
                    'goods-card__price-num--sale': isSale,
                    'goods-card__price-num--small': isSmall,
                }"
            >
                {{ discountPrice | formatPrice }}₽
            </div>
            <div
                v-if="isSale"
                class="goods-card__price-num goods-card__price-num--old"
                :class="{ 'goods-card__price-num--small-old': isSmall }"
            >
                {{ price | formatPrice }}₽
            </div>
        </div>

        <router-link
            @click.native.prevent="sendDataLayerEvent({ type: 'click' })"
            :to="{
                name: 'Product',
                params: { slug: product.slug },
            }"
            class="goods-card__name goods-card__z-top"
            :class="{ 'goods-card__name--small': isSmall }"
        >
            {{ product.shortName ? product.shortName : product.name }}
        </router-link>

        <button
            v-if="product.available"
            class="goods-card__btn goods-card__z-top"
            :class="{ 'goods-card__btn--loading': loading, 'goods-card__btn--small': isSmall }"
            @click="addCart"
        >
            <preloader :show="loading" sm-size></preloader>
            <span>{{ textForBtn }}</span>
        </button>

        <button
            v-else
            class="goods-card__btn goods-card__btn--preorder goods-card__z-top"
            @click="showPreOrderPopup"
        >
            <span>Предзаказ</span>
        </button>
    </div>
</template>

<style lang="scss">
    .goods-card {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        height: auto;

        &.swiper-slide {
            height: auto;
        }

        @include bp($bp-desktop-sm-for-card) {
            &:hover,
            &:focus-within {
                .goods-card__hover {
                    opacity: 1;
                }

                .goods-card__name {
                    color: var(--info);
                }
            }
        }
    }

    .goods-card--in-cart {
        .goods-card__btn {
            opacity: 1;
            background-color: transparent;
            color: var(--primary);

            &:hover {
                background-color: transparent;
                color: var(--primary--dark);
            }
        }
    }

    .goods-card--loading {
        .goods-card__btn {
            opacity: 1;
        }
    }

    .goods-card__hover {
        position: absolute;
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        top: -12px;
        left: -12px;
        z-index: 3;
        background: rgba(0, 26, 52, 0.03);
        border-radius: 16px;
        opacity: 0;
        transition: 0.25s ease-in-out all;
        outline: none;
    }

    .goods-card__img {
        position: relative;
        z-index: 2;
        margin-bottom: 8px;
    }

    .goods-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 133%;
        outline: none;

        img {
            @include center-absolute(0);
            margin: auto;
            max-height: 100%;
        }
    }

    .goods-card__labels {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .goods-card__label {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        padding: 2px 6px;
    }

    .goods-card__label--sale {
        background-color: var(--danger--light);
        color: #fff;
    }

    .goods-card__name {
        display: block;
        position: relative;
        z-index: 2;
        font-size: 13px;
        line-height: 18px;
        color: var(--primary-text);
        margin-bottom: 16px;
        padding-right: 10px;
        transition: 0.3s ease-in-out color;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 22px;
            padding-right: 20px;
            &:focus-visible {
                outline-offset: -1px;
            }
        }
    }

    .goods-card__name--small {
        font-size: 13px;
        line-height: 18px;
    }

    .goods-card__price {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 4px;
    }

    .goods-card__price-num {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }

    .goods-card__price-num--current {
        margin-right: 8px;
    }

    .goods-card__price-num--old {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #454f5b;
        text-decoration: line-through;
        margin-bottom: 3px;
    }

    .goods-card__price-num--sale {
        color: var(--danger--light);
    }

    .goods-card__price-num--small {
        font-size: 16px;
        line-height: 16px;
    }

    .goods-card__price-num--small-old {
        font-size: 12px;
        line-height: 14px;
    }

    .goods-card__btn {
        position: relative;
        padding: 4px 12px;
        background-color: var(--primary);
        border-radius: 16px;
        outline: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        margin-right: auto;
        margin-top: auto;
        border: 1px solid var(--primary);

        @include bp($bp-desktop-sm) {
            transition: 0.2s ease-in-out all;
            &:focus-visible {
                @include outline(0px);
            }
        }

        &:hover {
            background-color: var(--primary--dark);
            border-color: var(--primary--dark);
        }
    }

    .goods-card__btn--loading {
        color: transparent;
    }

    .goods-card__btn--preorder {
        color: #454f5b;
        border-color: #454f5b;
        background-color: transparent;

        &:hover {
            color: var(--primary-text);
            border-color: #182028;
            background-color: transparent;
        }
    }

    .goods-card__z-top {
        position: relative;
        z-index: 4;
    }
</style>
