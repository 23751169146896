var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isHide)?_c('div',{staticClass:"goods-card",class:{
        'goods-card--in-cart visible': _vm.inCart,
        'goods-card--loading visible': _vm.loading,
    }},[_c('div',{staticClass:"goods-card__img"},[_c('router-link',{staticClass:"goods-card__img-link js-lazy-load",attrs:{"to":{
                name: 'Product',
                params: { slug: _vm.product.slug },
            },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent({ type: 'click' })}}},[_c('img',{attrs:{"data-src":_vm.apiUrl(_vm.scrImage),"src":require('@/assets/images/preloader_card.gif'),"alt":""}})]),(_vm.isSale)?_c('div',{staticClass:"goods-card__labels"},[_c('div',{staticClass:"goods-card__label goods-card__label--sale"},[_vm._v(" -"+_vm._s(_vm.discountPercent)+"% ")])]):_vm._e()],1),_c('router-link',{staticClass:"goods-card__hover",attrs:{"to":{
            name: 'Product',
            params: { slug: _vm.product.slug },
        },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent({ type: 'click' })}}}),_c('div',{staticClass:"goods-card__price goods-card__z-top"},[_c('div',{staticClass:"goods-card__price-num goods-card__price-num--current",class:{
                'goods-card__price-num--sale': _vm.isSale,
                'goods-card__price-num--small': _vm.isSmall,
            }},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.discountPrice))+"₽ ")]),(_vm.isSale)?_c('div',{staticClass:"goods-card__price-num goods-card__price-num--old",class:{ 'goods-card__price-num--small-old': _vm.isSmall }},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.price))+"₽ ")]):_vm._e()]),_c('router-link',{staticClass:"goods-card__name goods-card__z-top",class:{ 'goods-card__name--small': _vm.isSmall },attrs:{"to":{
            name: 'Product',
            params: { slug: _vm.product.slug },
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent({ type: 'click' })}}},[_vm._v(" "+_vm._s(_vm.product.shortName ? _vm.product.shortName : _vm.product.name)+" ")]),(_vm.product.available)?_c('button',{staticClass:"goods-card__btn goods-card__z-top",class:{ 'goods-card__btn--loading': _vm.loading, 'goods-card__btn--small': _vm.isSmall },on:{"click":_vm.addCart}},[_c('preloader',{attrs:{"show":_vm.loading,"sm-size":""}}),_c('span',[_vm._v(_vm._s(_vm.textForBtn))])],1):_c('button',{staticClass:"goods-card__btn goods-card__btn--preorder goods-card__z-top",on:{"click":_vm.showPreOrderPopup}},[_c('span',[_vm._v("Предзаказ")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }